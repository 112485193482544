
// eslint-disable-next-line
const DashboardIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_569_11952)">
        <path d="M9.37437 1.39906V9.37339H1.40003V1.39906H9.37437ZM10.7734 0H0.80101C0.358991 0 0.000976562 0.358015 0.000976562 0.800033V10.7724H10.7734V0Z" fill="#626F86"/>
        <path d="M9.37437 14.6096V22.5839H1.40003V14.6096H9.37437ZM10.7734 13.2105H0.000976562V23.183C0.000976562 23.625 0.358991 23.983 0.80101 23.983H10.7734V13.2105Z" fill="#626F86"/>
        <path d="M14.6266 14.6136L22.6009 14.6256L22.5889 22.5999L14.6146 22.5879L14.6266 14.6136ZM13.2295 13.2125L13.2125 23.985L23.1849 24C23.627 24 23.986 23.643 23.986 23.201L24.001 13.2285L13.2285 13.2115L13.2295 13.2125Z" fill="#626F86"/>
        <path d="M22.5849 1.39906V9.37339H14.6106V1.39906H22.5849ZM13.2115 0V10.7724H23.984V0.800033C23.984 0.358015 23.626 0 23.184 0H13.2115Z" fill="#626F86"/>
        </g>
        <defs>
        <clipPath id="clip0_569_11952">
        <rect width="24.001" height="24" fill="white" transform="translate(0.000976562)"/>
        </clipPath>
        </defs>
        </svg>
    );
}

export default DashboardIcon