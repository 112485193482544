import { useSelector } from "react-redux";
import { Container, Box, Typography, Button, Stack } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const EmailSetUp = ({ data = {} }) => {
    const navigate = useNavigate();

    const workspaceDetails = useSelector(
        (state) => state.workspace.workspaceDetails
    );

    const handleCreateEmailAccount = () => {
        if (workspaceDetails?.status !== "completed") {
            navigate("/employee/createUser", { state: { stepName: "Set Up Email Accounts" } });
        } else {
            navigate("/employee/createUser");
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    py: 8,
                    px: 3,
                }}
            >
                <CheckCircle
                    sx={{
                        fontSize: 80,
                        color: 'success.main',
                        mb: 3,
                    }}
                />

                <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
                    Workspace Setup Complete! 🎉
                </Typography>

                <Typography variant="body1" color="text.secondary" paragraph>
                    Your workspace has been successfully configured and is ready to use.
                </Typography>

                {data?.domain && (
                    <Typography variant="body1" color="text.primary" paragraph>
                        Domain: <strong>{data?.domain}</strong>
                    </Typography>
                )}

                <Stack spacing={2} sx={{ mt: 4, width: '100%' }}>
                    <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={() => navigate('/')}
                        sx={{ py: 1.5 }}
                    >
                        Go to Dashboard
                    </Button>

                    {workspaceDetails?.status !== "completed" && <Button
                        variant="outlined"
                        size="large"
                        fullWidth
                        onClick={handleCreateEmailAccount}
                        sx={{ py: 1.5 }}
                    >
                        Create Email
                    </Button>}
                </Stack>

                <Typography variant="body2" color="text.secondary" sx={{ mt: 3 }}>
                    Need help? Contact our support team at support@sarv.com
                </Typography>
            </Box>
        </Container>
    );
};

export default EmailSetUp;