const PopoverIcon = ({ color = "white", width = "25", height = "25" }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_9613_29058"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="25"
        >
          <rect x="0.640625" y="0.419922" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_9613_29058)">
          <path
            d="M4.64062 20.4199C4.09062 20.4199 3.61979 20.2241 3.22813 19.8324C2.83646 19.4408 2.64062 18.9699 2.64062 18.4199V6.41992C2.64062 5.86992 2.83646 5.39909 3.22813 5.00742C3.61979 4.61576 4.09062 4.41992 4.64062 4.41992H20.6406C21.1906 4.41992 21.6615 4.61576 22.0531 5.00742C22.4448 5.39909 22.6406 5.86992 22.6406 6.41992V18.4199C22.6406 18.9699 22.4448 19.4408 22.0531 19.8324C21.6615 20.2241 21.1906 20.4199 20.6406 20.4199H4.64062ZM4.64062 18.4199H20.6406V6.41992H4.64062V18.4199ZM11.6406 17.4199H19.6406V11.4199H11.6406V17.4199ZM13.6406 15.4199V13.4199H17.6406V15.4199H13.6406Z"
            fill={color} // Use the color prop here
          />
        </g>
      </svg>
    );
  };
  
  export default PopoverIcon;