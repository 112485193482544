import { Box, IconButton } from '@mui/material'
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Logger from '../../lib/Logger';
import { handleRedirect } from '../../utils/common';
const logger = new Logger("renderIframe");

const RenderIframePopup = ({chatId = "", setChatId}) => {
    const redirectToChat = async () => {
        try{
         await handleRedirect("chat", "", {}, true, "", false, "", `?id=${chatId}`)
        } catch(error) {
            logger.error(error);
        }
    }
    return (
        <Box sx={{
            position: 'absolute',
            bottom: '0px',
            right: '30px',
            zIndex: 9999,
            // height: '400px',
            // width: '300px'
            borderRadius: '6px',
            border: '1px solid',
            borderColor: 'grey.300',
            overflow: 'hidden'
        }}>
            <iframe
                src={`https://dev-console.sarv.com/chat/u/0/?id=${chatId}&isIframe=true`}
                title="web chat popup"
                height={500}
                width={420}
                style={{ border: 'none' }}
            ></iframe>

            <Box sx={{
                position: 'absolute',
                top: '6px',
                right: '4px',
                zIndex: 9999,
                // display: 'flex',
                // flexDirection: 'row',
                // gap: '3px'
            }}>
                <IconButton onClick={() => redirectToChat()}>
                    <OpenInFullIcon sx={{ width: '16px', height: '16px' }} />
                </IconButton>
                <IconButton  onClick={()=> setChatId(null)}>
                    <CloseIcon sx={{ width: '20px', height: '20px' }} />
                </IconButton>
            </Box>

        </Box>
    )
}

export default RenderIframePopup