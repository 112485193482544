import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Grid, Box, Typography, SvgIcon,
    Avatar, Button, TableCell as TableCellActual,
    TableRow as TableRowActual
} from '@mui/material';
import Upload2LineIcon from '../../assets/svgs/icons/upload-2-line';
import ListCheckLineIcon from '../../assets/svgs/icons/list-check-line';
import FunctionLineIcon from '../../assets/svgs/icons/function-line';
import { Stack } from '@mui/system';
import { configFile } from '../../config';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { formatBytes } from '../../utils/common';
import { handleRedirect } from "../../utils/common";
import LinearLoader from '../../components/Loader/LinearLoader';
import { useSnackbar } from '../../components/snackbar';
import Logger from '../../lib/Logger';
import FileCard from './driveThumbnail';

const logger = new Logger("driveSection")

const { imagesUrl } = configFile;

const TableCell = styled(TableCellActual)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.grey[800],
}));

const TableRow = styled(TableRowActual)(({ theme }) => ({
    color: theme.palette.grey[500],
    fontSize: '14px',
    fontWeight: 500
}));

const RenderDriveOwner = ({ row }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Avatar sx={{ width: '24px', height: '24px' }}>
            </Avatar>
            {row.ownerName}
        </Box>
    )
}

const DriveSection = ({ data, driveLoading, driveProgress, products, handleHighlight, documentType, setDocumentType }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [layout, setLayout] = useState("list");

    const handleRedirectToDrive = async () => {
        try {
            if (!products.includes("Drive")) {
                handleHighlight();
                return enqueueSnackbar("You currently do not have access to the Drive product. Please reach out to your Administrator for assistance.", {
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: "error"
                });

            }
            await handleRedirect("drive", "", {}, true, "", false, "", "?u=open");
        } catch (error) {
            logger.error("upcomingMeetSection fn: [handleJoin] api:[shareSession] post catchError", error);

        }
    }
    const handleFileClick = async (_id) => {
        try {
            // Check if the user has access to the Drive product
            if (!products.includes("Drive")) {
                handleHighlight();
                return enqueueSnackbar("You currently do not have access to the Drive product. Please reach out to your Administrator for assistance.", {
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: "error"
                });
            }
    
            // Redirect with the _id as a query parameter
            await handleRedirect("drive", "", {}, true, "", false, "", `/document-viewer?d=${_id}`);
        } catch (error) {
            logger.error("DriveSection fn: [handleFileClick] api:[shareSession] post catchError", error);
        }
    };

    return (
        <Grid container spacing={2} sx={{
            minHeight: '377px',
            height: '377px',
            paddingLeft: '0px',
            paddingRight: '0px'
        }}>
            <Grid item xs={12} sx={{
                borderRadius: '8px'
            }}>
                <Box sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    height: '100%',
                    //  height: '400px', // Fixed height for the container
                    // overflow: 'auto'
                    overflow: 'hidden'
                }}>
                    <Box sx={{
                        height: '64px',
                        width: '100%',
                        backgroundColor: 'card.main',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '4px',
                            alignItems: 'center'
                        }}>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    color: 'grey.800',
                                    mr: '12px'
                                }}>
                                Recents
                            </Typography>

                            <Button
                                variant='outlined'
                                sx={{
                                    color: documentType === "all" ? 'grey.0' : 'grey.500',
                                    backgroundColor: documentType === "all" && 'grey.800',
                                    '&:hover': {
                                        backgroundColor: (theme) => documentType === "all" ? theme.palette.grey[800] : theme.palette.grey[200], // Disable hover color change
                                    },
                                    border: '1px solid',
                                    borderColor: 'grey.500',
                                }}
                                onClick={() => setDocumentType("all")}
                            >
                                All
                            </Button>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '1px solid',
                                    borderColor: 'grey.500',
                                    color: documentType === "document" ? 'grey.0' : 'grey.500',
                                    backgroundColor: documentType === "document" && 'grey.800',
                                    '&:hover': {
                                        backgroundColor: (theme) => documentType === "document" ? theme.palette.grey[800] : theme.palette.grey[200], // Disable hover color change
                                    },
                                }}
                                onClick={() => setDocumentType("document")}
                            >
                                Document
                            </Button>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '1px solid',
                                    borderColor: 'grey.500',
                                    color: documentType === "spreadsheet" ? 'grey.0' : 'grey.500',
                                    backgroundColor: documentType === "spreadsheet" && 'grey.800',
                                    '&:hover': {
                                        backgroundColor: (theme) => documentType === "spreadsheet" ? theme.palette.grey[800] : theme.palette.grey[200], // Disable hover color change
                                    },
                                }}
                                onClick={() => setDocumentType("spreadsheet")}
                            >
                                Spreadsheet
                            </Button>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '1px solid',
                                    borderColor: 'grey.500',
                                    color: documentType === "presentation" ? 'grey.0' : 'grey.500',
                                    backgroundColor: documentType === "presentation" && 'grey.800',
                                    '&:hover': {
                                        backgroundColor: (theme) => documentType === "presentation" ? theme.palette.grey[800] : theme.palette.grey[200], // Disable hover color change
                                    },
                                }}
                                onClick={() => setDocumentType("presentation")}
                            >
                                Presentation
                            </Button>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '1px solid',
                                    borderColor: 'grey.500',
                                    color: documentType === "pdf" ? 'grey.0' : 'grey.500',
                                    backgroundColor: documentType === "pdf" && 'grey.800',
                                    '&:hover': {
                                        backgroundColor: (theme) => documentType === "pdf" ? theme.palette.grey[800] : theme.palette.grey[200], // Disable hover color change
                                    },
                                }}
                                onClick={() => setDocumentType("pdf")}
                            >
                                PDF
                            </Button>

                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px', alignItems: 'center' }}>
                            <Button
                                sx={{
                                    color: 'grey.600',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                }}
                                onClick={handleRedirectToDrive}
                                startIcon={
                                    <SvgIcon sx={{ width: '20px', height: '20px' }}>
                                        <Upload2LineIcon color={'currentColor'} />
                                    </SvgIcon>
                                }
                            >
                                Upload
                            </Button>

                            <SvgIcon onClick={() => setLayout("list")} sx={{ width: '20px', height: '20px' }}>
                                <ListCheckLineIcon color={'currentColor'} />
                            </SvgIcon>
                            <SvgIcon onClick={() => setLayout("grid")} sx={{ width: '20px', height: '20px' }}>
                                <FunctionLineIcon color={'currentColor'} />
                            </SvgIcon>
                        </Box>
                    </Box>
                    
                    {driveLoading.value && <LinearLoader progress={driveProgress} />}

                    {!driveLoading.value && <>

                        {data?.length > 0 ? <>
                            {layout === "list" && <TableContainer
                                component={Paper}
                                sx={{
                                    borderRadius: '0px',
                                    maxHeight: '295px',
                                    overflow: 'auto',
                                }}>
                                <Table sx={{ minWidth: 650, }} stickyHeader aria-label="simple table" size='small'>
                                    <TableHead >
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="left">Owner</TableCell>
                                            <TableCell align="center">Last Modified</TableCell>
                                            <TableCell align="center">File Size</TableCell>
                                            {/* <TableCell align="right">Actions</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        {data.map((row) => (
                                            <TableRow
                                                key={row._id}
                                                onClick={() => handleFileClick(row._id)}
                                                sx={{
                                                    cursor: 'pointer',
                                                    '&:last-of-type': {
                                                        borderBottom: 'solid',
                                                        borderColor: 'grey.100',
                                                        borderCollapse: 'unset'
                                                    },
                                                    '&:first-of-type': {
                                                        borderTop: 'solid',
                                                        borderColor: 'grey.100',
                                                        borderCollapse: 'unset'
                                                    }
                                                    // '&:last-child td, &:last-child th': { border: 0 },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <Typography
                                                        variant='subtitle2'
                                                    >

                                                        {row.originalName}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align="left"><RenderDriveOwner row={row} /></TableCell>
                                                <TableCell align="center">
                                                    {row.lastModifiedAt || "--"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography variant='subtitle2'>

                                                        {formatBytes(row.fileSize)}
                                                    </Typography>
                                                </TableCell>
                                                {/* <TableCell align="right">{row.protein}</TableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>}
                            {
                                layout === "grid" && <FileCard files={data} onClick={(file) => handleFileClick(file._id)} />
                            }
                        </>
                            :
                            <Stack spacing={1} justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 56px)', width: '100%' }}>
                                <img src={imagesUrl?.diveNoData} alt='No more data' style={{ width: '40px', height: '40px' }} />
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        fontWeight: 600,
                                        color: 'grey.700'
                                    }}>
                                    No recent items
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        color: 'grey.500'
                                    }}>
                                    Create something new or upload an item
                                </Typography>
                            </Stack>}
                    </>}

                </Box>
            </Grid>
        </Grid>
    )
}

export default DriveSection