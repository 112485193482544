import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";

//pages
import Dashboard from "../pages/Dashboard";
import Sidebar from "../layouts/Sidebar";
import Login from "../pages/Auth/Login";
import SignUp from "../pages/Auth/Signup";
import ForgotPwd from "../pages/Auth/ForgotPwd";
import Verification from "../pages/Auth/Verification";
import { ProtectedRoute, PublicRoute } from "./hoc";
import Spinner from "../pages/spinner";
import Navbar from "../layouts/Navbar";
import Settings from "../pages/settings";
import ComingSoon from "../pages/comingSoon";
import CustomSession from "../pages/customSession";
import { allowedRoute } from "../utils/common";
import Chat from "../pages/chat";
import Privacy from "../pages/privacy";
import UsersList from "../pages/usersList";
import NewUsers from "../pages/newUsers";
import Employeelist from "../pages/employeelist";
import Branding from "../pages/branding";
import ReferenceLogin from "../pages/referenceLogin";
import Plans from "../pages/plans";
import Contacts from "../pages/contactUs";
import Checkout from "../pages/Orders/order";
import TypeAccount from "../pages/Auth/Gsuite/Type";

import CreateWorkspace from "../sections/users/createWorkspace";
import CreateDomain from "../sections/users/createDomain";
import Invitation from "../pages/Invitation";
import Logout from "../pages/logout";
import MyPlans from "../pages/myPlans";
import WalletTabPanel from "../pages/paymentInvoice";
import ProfileMenuAccount from "../pages/addAccount";
import AddAccountPopOver from "../pages/addAccountIframe";
import RenderIframePopup from "../pages/Dashboard/renderIframePopup";
import PDFViewer from "../pages/pdfViewer";
// import { useSelector } from "react-redux";

export default function AllRoutes() {
  /*  const workspaceDetails = useSelector(
     (state) => state.workspace.workspaceDetails
   ); */
  return (
    <>
      <Route
        element={
          <>
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          </>
        }
      >
        <Route
          path="/"
          element={
            <>
              <Sidebar /> <Navbar /> <Outlet />{" "}
            </>
          }
        >
          <Route index element={<Dashboard />} />
          <Route exact path="/deepCall" element={<ComingSoon />} />
          <Route exact path="/SMS" element={<Dashboard />} />
          {/* <Route exact path="/chat" element={<ComingSoon />} /> */}
          <Route exact path="/voice" element={<ComingSoon />} />
          <Route exact path="/settings" element={<Settings />} />
          <Route exact path="/users" element={<UsersList />} />
          {/* create user */}
          <Route
            exact
            path="/:id/createUser"
            element={<NewUsers />
            }
          />
          <Route exact path="/employeeList" element={<Employeelist />} />
          <Route exact path="/createworkspace" element={<CreateWorkspace />} />
          <Route exact path="/createdomain" element={<CreateDomain />} />
          <Route exact path="/:id/updateUser" element={<NewUsers />} />
          <Route exact path="/branding" element={<Branding />} />
          <Route exact path="/checkout" element={<Checkout />} />{" "}
          <Route exact path="/myPlans" element={<MyPlans />} />
          <Route exact path="/invoices" element={<WalletTabPanel />} />
          <Route exact path="/more" element={<Dashboard />} />
          <Route exact path="/logout" element={<Logout />} />
          <Route exact path="/testiframe" element={<RenderIframePopup />} />
        </Route>
      </Route>
      <Route
        element={
          <>
            <PublicRoute>
              {" "}
              <Outlet />{" "}
            </PublicRoute>{" "}
          </>
        }
      >
        {allowedRoute("signUp") && (
          <Route path="/register" element={<SignUp />} />
        )}
        {allowedRoute("forgetPassword") && (
          <Route path="/forget-password" element={<ForgotPwd />} />
        )}
        {allowedRoute("TypeAccountForm") && (
          <Route path="/Type-Account-Form" element={<TypeAccount />} />
        )}

        {allowedRoute("invitation") && (
          <Route path="/invitation" element={<Invitation />} />
        )}
      <Route path="/login" element={<Login />} />

        <Route path="/verification" element={<Verification />} />
      </Route>
      <Route exact path="/spinner" element={<Spinner />} />
      <Route exact path="/refLogin" element={<ReferenceLogin />} />
      <Route exact path="/addAccount" element={<AddAccountPopOver />} />
      <Route path="/leads" element={<Chat />} />
      <Route exact path="/customSession" element={<CustomSession />} />
      <Route exact path="/privacy" element={<Privacy />} />
      <Route exact path="/noProducts" element={<>Action not permitted </>} />
      <Route exact path="/profileMenu" element={<ProfileMenuAccount />} />
      <Route exact path="/plans" element={<Plans />} />
      <Route exact path="/contact" element={<Contacts />} />
      <Route exact path="/help" element={<PDFViewer/>}/>
      <Route exact path="/authLogin" element={<Login/>} />

    </>
  );
}
