// MXRecordsVerification.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, Alert, Button, IconButton, CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import { copyToClipBoard, parseDKIMRecord, parseMXRecord, parseSPFRecord } from '../../../utils/common';
import { renderErrorMessage, renderSuccessMessage } from '../../../lib/toastMessage';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch } from 'react-redux';
import { updateWorkspace } from '../../../redux/action/workspaceAction';
import { axiosGetCall } from '../../../services/apisCall';
import RefreshIcon from '@mui/icons-material/Refresh';

const colorMapper = {
    matched: "success",
    notMatched: "info",
    notExist: "error"
}

const MXRecordsVerification = ({ data, setUpEmailServer, generatedkimRecords, verifyMXDkimRecords, workspaceId }) => {
    const [mxRecords, setMXRecords] = useState([]);
    const [dkimRecords, setDKIMRecords] = useState({});
    const [spfRecords, setSPFRecords] = useState({});
    const [dmarcRecord, setDMARCRecord] = useState({});
    const [mxloading, setMXLoading] = useState(true);
    const [dkimloading, setDKIMLoading] = useState(true);
    const dispatch = useDispatch();


    const fetchWorkSpaceDetails = async () => {
        try {
            if (workspaceId) {
                const { data = {}, isDomainRegistered } = await axiosGetCall(
                    "workspaceProgress",
                    { workspaceName: workspaceId }
                );
                dispatch(
                    updateWorkspace({
                        workspace: workspaceId,
                        workspaceDetails: { ...data, isDomainRegistered },
                    })
                );
            }
        } catch (error) {
            console.error(
                "WorkspaceSteps fn: [fetchWorkSpaceProgress] api:[consoleDetails] get catchError",
                error
            );
        }
    };

    useEffect(() => {
        if (data?.details?.dns_mx) {
            const mxRecords = parseMXRecord(data?.details?.dns_mx);
            setMXRecords([mxRecords]);
        }

        if (data?.details?.dns_dkim) {
            const dkimRecords = parseDKIMRecord(data?.details?.dns_dkim, "dkim");
            setDKIMRecords(dkimRecords);
        }

        if (data?.details?.dns_dmarc) {
            const dmarcRecord = parseDKIMRecord(data?.details?.dns_dmarc, "dmarc");
            setDMARCRecord(dmarcRecord);
        }

        if (data?.details?.dns_spf) {
            const spfRecords = parseSPFRecord(data?.details?.dns_spf);
            setSPFRecords(spfRecords);
        }

    }, [data])

    const copyDetails = (data) => {
        copyToClipBoard(data);
        return renderSuccessMessage("Copied to clipboard")
    }

    const handleCreateDomain = async () => {
        try {
            await setUpEmailServer();
            setMXLoading(false);

        } catch (error) {
            renderErrorMessage(error?.message || "Network error!");
        }
    }

    const handleDkimRecordsGeneration = async () => {
        try {
            await generatedkimRecords();
            setDKIMLoading(false);
        } catch (error) {
            renderErrorMessage(error?.message || "Network error!");
        }
    }

    useEffect(() => {

        if (data?.currentStep === "Create Domain") {
            handleCreateDomain();
        }

        if (data?.currentStep === "Generate DKIM and DMARC KEYS") {
            handleDkimRecordsGeneration();
        }

        if (data?.currentStep === "Verify MX and Other Records") {
            setMXLoading(false);
            setDKIMLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (

        !(mxloading || dkimloading) ? <Box sx={{ p: 4, border: '1px solid', borderColor: 'grey.300', borderRadius: '6px', padding: '22px 25px' }}>
            {/* Top Bar */}
            {/* <Box sx={{ bgcolor: 'primary.main', color: 'white', p: 2, mb: 2 }}>
                <Typography variant="h6">Activate Gmail</Typography>
            </Box> */}

            {/* Warning Section */}
            {/* <Alert severity="error" sx={{ mb: 2 }}>
                Your MX records didn't match. You'll need to delete old and add the missing records below.
            </Alert> */}

            <Typography variant="h6" sx={{ mb: 2 }}>MX Record</Typography>
            {/* MX Records Table */}
            <TableContainer component={Paper} sx={{ border: '1px solid', borderColor: 'grey.300' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Priority</TableCell>
                            <TableCell>Host Record</TableCell>
                            <TableCell>Points To</TableCell>
                            <TableCell>TTL</TableCell>
                            <TableCell sx={{ minWidth: "200px" }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mxRecords.map((record, index) => (
                            <TableRow key={index}>
                                <TableCell>{record.priority}</TableCell>
                                <TableCell>{record.host}</TableCell>
                                <TableCell>{record.pointsTo}
                                    <IconButton onClick={() => copyDetails(record.pointsTo)} >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>{record.ttl}</TableCell>
                                <TableCell >
                                    <Box sx={{ display: "flex", gap: "10px" }}>

                                        <Alert sx={{ width: "50%" }} severity={colorMapper?.[data?.domainConfigrationDetail?.mxRecords]}>{data?.domainConfigrationDetail?.mxRecords}</Alert>
                                        {data?.domainConfigrationDetail?.mxRecords !== "matched" && <IconButton onClick={fetchWorkSpaceDetails} color='primary'>
                                            <RefreshIcon />
                                        </IconButton>}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Remove */}
            <Stack direction="row" spacing={2}
                sx={{
                    display: data?.currentStep === "Generate DKIM and DMARC KEYS" ? "block" : "none",
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ float: 'left' }}
                    onClick={generatedkimRecords}
                >
                    Generate Dkim Record
                </Button>
            </Stack>


            <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>SPF Record</Typography>
            <TableContainer component={Paper} sx={{ border: '1px solid', borderColor: 'grey.300' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Host Record</TableCell>
                            <TableCell>Points To</TableCell>
                            <TableCell>TTL</TableCell>
                            <TableCell sx={{ minWidth: "250px" }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{spfRecords.host}</TableCell>
                            <TableCell>
                                {spfRecords.content}
                                <IconButton onClick={() => copyDetails(spfRecords.content)} >
                                    <ContentCopyIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell>{spfRecords.ttl}</TableCell>
                            <TableCell>

                                <Box sx={{ display: "flex", gap: "10px" }}>

                                    <Alert sx={{ width: "50%" }} severity={colorMapper?.[data?.domainConfigrationDetail?.spfRecord]}>{data?.domainConfigrationDetail?.spfRecord}</Alert>

                                    {data?.domainConfigrationDetail?.spfRecord !== "matched" && <IconButton onClick={fetchWorkSpaceDetails} color='primary'>
                                        <RefreshIcon />
                                    </IconButton>}
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {data?.currentStep === "Verify MX and Other Records" && <>

                {/* DKIM Record Table */}
                <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>DKIM Record</Typography>
                <TableContainer component={Paper} sx={{ border: '1px solid', borderColor: 'grey.300' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Host Record</TableCell>
                                <TableCell>Points To</TableCell>
                                <TableCell>TTL</TableCell>
                                <TableCell sx={{ minWidth: "300px" }}>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{dkimRecords.name}</TableCell>
                                <TableCell sx={{ maxWidth: '500px' }}>
                                    <Typography sx={{
                                        inlineSize: '500px',
                                        overflowWrap: 'break-word'
                                    }}>
                                        {dkimRecords.content}
                                        <IconButton onClick={() => copyDetails(dkimRecords.content)} >
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Typography>
                                </TableCell>
                                <TableCell>{dkimRecords.ttl}</TableCell>
                                <TableCell>


                                    <Box sx={{ display: "flex", gap: "10px" }}>

                                        <Alert sx={{ width: "50%" }} severity={colorMapper?.[data?.domainConfigrationDetail?.dkimRecords]}>{data?.domainConfigrationDetail?.dkimRecords}</Alert>

                                        {data?.domainConfigrationDetail?.dkimRecords !== "matched" && <IconButton onClick={fetchWorkSpaceDetails} color='primary'>
                                            <RefreshIcon />
                                        </IconButton>}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* DMARC Record Table */}
                <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>DMARC Record</Typography>
                <TableContainer component={Paper} sx={{ border: '1px solid', borderColor: 'grey.300' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Host Record</TableCell>
                                <TableCell>Points To</TableCell>
                                <TableCell>TTL</TableCell>
                                <TableCell sx={{ minWidth: "300px" }}>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{dmarcRecord.name}</TableCell>
                                <TableCell>{dmarcRecord.content}
                                    <IconButton onClick={() => copyDetails(dmarcRecord.content)} >
                                        <ContentCopyIcon />
                                    </IconButton>

                                </TableCell>
                                <TableCell>{dmarcRecord.ttl}</TableCell>
                                <TableCell>



                                    <Box sx={{ display: "flex", gap: "10px" }}>

                                        <Alert sx={{ width: "50%" }} severity={colorMapper?.[data?.domainConfigrationDetail?.dmarcRecords]} >{data?.domainConfigrationDetail?.dmarcRecords}</Alert>

                                        {data?.domainConfigrationDetail?.dmarcRecords !== "matched" && <IconButton onClick={fetchWorkSpaceDetails} color='primary'>
                                            <RefreshIcon />
                                        </IconButton>}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

            </>}

            {<Stack direction="row" spacing={2}
                sx={{
                    display: data?.completed ? "none" : "block",
                    mt: 3
                }}
            >
                <Button
                    variant="contained"
                    color="success"
                    sx={{ float: 'right' }}
                    onClick={verifyMXDkimRecords}
                >
                    Verify
                </Button>
            </Stack>}

            {/* Troubleshooting Guide */}
            <Box sx={{ mt: 8 }}>
                <Typography variant="h6">Troubleshooting Guide</Typography>
                <Box sx={{ mt: 2 }}>
                    <Alert severity="info">
                        <strong>Need to delete:</strong> On the DNS page, go to the MX section and delete this record.
                    </Alert>
                    <Alert severity="error" sx={{ mt: 1 }}>
                        <strong>Missing:</strong> On the DNS page, go to the respective section and add this record.
                    </Alert>
                    <Alert severity="success" sx={{ mt: 1 }}>
                        <strong>Added:</strong> Great work! This record was correctly added.
                    </Alert>
                </Box>
            </Box>
        </Box > :
            (mxloading) ? (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                <CircularProgress size={50} /> {/* Loading spinner */}
                <Typography variant="body1" sx={{ mt: 2, color: 'text.secondary' }}>
                    Generating MX Records...
                </Typography>
            </Box>) :
                (dkimloading) ? (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                    <CircularProgress size={50} /> {/* Loading spinner */}
                    <Typography variant="body1" sx={{ mt: 2, color: 'text.secondary' }}>
                        Generating DKIM Records...
                    </Typography>
                </Box>)
                    : <></>
    )
};

export default MXRecordsVerification;
