import { getItem } from "../../lib/cookies";
// import { axiosGetCall } from "../../services/apisCall";




const initialState = {
    workspace: getItem('workspace') || "",
    workspaceDetails: {},
    workspaces: []
};

const workspaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_WORKSPACE":
            const workspace = action.payload?.workspace;
            return {
                ...state,
                workspace,
                workspaceDetails: action.payload?.workspaceDetails || {}, 
                isDomainRegistered: action.payload?.workspaceDetails?.isDomainRegistered,
            };
        case "SET_WORKSPACES":
            return { ...state, workspaces: action.payload };
        case "APPEND_WORKSPACE":
            return {
                ...state,
                workspaces: [...state.workspaces, action.payload],
            };
        default:
            return state;
    }
};

export default workspaceReducer;

