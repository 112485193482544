import React, { useState } from "react";
import { Box, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { imagesUrl } from "../config/commonConfig";

const pdfFiles = [
    { name: "Auth Guide", url: imagesUrl?.loginGuide },
    { name: "Driver Guide", url: imagesUrl?.driveGuide },
    { name: "Meet Guide", url: imagesUrl?.meetGuide },
    { name: "Webinar Guide", url: imagesUrl?.webinarGuide },
];

const PdfViewer = () => {
    const [selectedPdf, setSelectedPdf] = useState(pdfFiles[0]);

    return (
        <Box sx={{ display: "flex", height: "100vh" }}>
            {/* Left Sidebar */}
            <Box sx={{ width: "20%", borderRight: "1px solid #ccc", overflowY: "auto", padding: 1 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                    PDF List
                </Typography>
                <List sx={{ padding: 0 }}>
                    {pdfFiles.map((pdf, index) => (
                        <ListItem key={index} disablePadding sx={{ marginBottom: 0.5 }}>
                            <ListItemButton
                                selected={selectedPdf === pdf}
                                onClick={() => setSelectedPdf(pdf)}
                                sx={{
                                    padding: 0.5,
                                    borderRadius: 1,
                                    "&:hover": {
                                        backgroundColor: "grey.100", // Light gray background on hover
                                    },
                                }}
                            >
                                <Typography variant="subtitle2" >
                                    {pdfFiles[index]?.name}
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>

            {/* Right Content */}
            <Box sx={{ flex: 1, padding: 2, overflowY: "auto" }}>
                {/* PDF Viewer (using iframe) */}
                <Box sx={{ border: "1px solid #ccc", borderRadius: 1, overflow: "hidden" }}>
                    <iframe
                        src={selectedPdf?.url}
                        title="PDF Viewer"
                        style={{
                            width: "100%",
                            height: "96vh", // Adjust height as needed
                            border: "none",
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default PdfViewer;