import jsCookie from "js-cookie";
import jwtDecode from "jwt-decode";
import PropTypes from "prop-types";


// Get Single cookie data.
// const getItem = (key) => {

// if (checkIframe()) {
//   const refData = jsCookie.get(key);
//   const data = JSON.parse(refData);
//   return data[key];
// }
//   return jsCookie.get(key);
// };


// const checkRefLogin = () => {
//   console.log("🚀 ~ checkRefLogin ~ getIframeId():", getIframeId())
//   if(getIframeId() === "reflogin") {
//     return true;
//   } 
//   return false;
// }

const decodeToken = (reverse = false) => {
  const token = getItem("token");
  if (token) {
    try {
      const jwtToken = jwtDecode(token);
      return jwtToken;
      // Proceed with using the decoded token
    } catch (error) {
      console.error("Invalid token:", error.message);
    }
  } else {
    console.error("No token found in cookies");
  }
}

function checkRefLogin() {
  let currentWindow = window;

  while (currentWindow !== window.top) {
    const frame = currentWindow.frameElement;
    if (frame && frame.id === "reflogin") {
      return true; // Return true if the iframe ID matches
    }
    currentWindow = currentWindow.parent; // Move up the iframe hierarchy
  }

  return false;
}

const getItem = (key) => {
  const secureField = ["exp", "sessionToken", "token"];
  if (key === "workspace") {
  const workSpace = localStorage.getItem("workspace");
  const parsedWorkSpace = workSpace ? JSON.parse(workSpace) : {};
  const token = decodeToken();
  return parsedWorkSpace[token?.userId] || null;
}
  if (secureField.includes(key)) {
    const currentPath = window.location.pathname.split("/");
    const isIframe =  checkRefLogin();
    const secureToken = isIframe ? jsCookie.get("refSecureToken") : jsCookie.get("secureToken");
    if (secureToken) {
      const parsedToken = JSON.parse(secureToken);
      if (!isIframe && currentPath && currentPath.length >= 3) {
        if (key === "token") {
          const localToken = localStorage.getItem("token") || [];
          const parsedLocalToken = JSON.parse(localToken);
          const currentPos = currentPath[2];
          return parsedLocalToken[currentPos];
        }
        const result = parsedToken[currentPath[2]]?.[key];
        return result;
      } else {
        const token = localStorage.getItem("token");
        if (!isIframe && key === "token" && token) {
          // const localToken = localStorage.getItem("token");
          const parsedLocalToken = JSON.parse(token);
          return parsedLocalToken[0];
        }
        const result = parsedToken[0]?.[key];
        return result;
      }
    }
    else {
      return jsCookie.get(key);
    }
  } else {
    return jsCookie.get(key);
  }
}

getItem.propTypes = {
  key: PropTypes.string.isRequired, // String type key requried
};

// Get multiple cookees data.
const getBulkItems = (keyNamesArray) => {
  // console.log("Inside bulk Item", keyNamesArray);
  return keyNamesArray.map((key) => {
    const value = getItem(key);
    const planeKey = key.split("-")
    return { [planeKey.length > 0 ? planeKey[0] : key]: value };
  });
};

getBulkItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  // required array formate which is array of strings i.e ["key1", "key2", ...]
};

// Set single cookie.
// const setItem = (key, value, exp = 1, path = "/") => {
//   return jsCookie.set(key, value, {
//     expires: exp,
//     path,
//     sameSite: "none",
//     secure: true,
//   });
// };

const setItem = (key, value, exp = 1, path = "/") => {
  const secureField = ["exp", "sessionToken", "token"];

  if(key === "workspace") {
    const workSpace = localStorage.getItem("workspace");
    const parsedWorkSpace = workSpace ? JSON.parse(workSpace): {};
    const token = decodeToken();
    parsedWorkSpace[token?.userId] = value;
    localStorage.setItem("workspace", JSON.stringify(parsedWorkSpace));
    return;
  }
  if (secureField.includes(key)) {
    const currentPath = window.location.pathname.split("/");
    const isIframe = checkRefLogin();
    const secureToken = isIframe ? jsCookie.get("refSecureToken") : jsCookie.get("secureToken");
    let parsedToken = secureToken ? JSON.parse(secureToken) : {};

    if(isIframe) {
      parsedToken[0][key] = value;
      return jsCookie.set("refSecureToken", JSON.stringify(parsedToken));
    }

    if (currentPath && currentPath.length >= 3) {
      const pathKey = currentPath[2];
      if (key === "token") {
        const localToken = localStorage.getItem("token");
        const parsedLocalToken = JSON.parse(localToken);
        parsedLocalToken[pathKey] = value;
        localStorage.setItem("token", JSON.stringify(parsedLocalToken));
        return;
      }

      parsedToken[pathKey] = parsedToken[pathKey] || {};
      parsedToken[pathKey][key] = value;
    } else {
      if (key === "token") {
        const localToken = localStorage.getItem("token") || JSON.stringify([]);
        const parsedLocalToken = JSON.parse(localToken);
        parsedLocalToken[0] = value;
        localStorage.setItem("token", JSON.stringify(parsedLocalToken));
        return;
      }
      parsedToken[0] = parsedToken[0] || {};
      parsedToken[0][key] = value;
    }
    return jsCookie.set("secureToken", JSON.stringify(parsedToken), {
      expires: exp,
      path,
      sameSite: "none",
      secure: true,
    });
  } else {
    return jsCookie.set(key, value, {
      expires: exp,
      path,
      sameSite: "none",
      secure: true,
    });
  }
};


setItem.propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  exp: PropTypes.number,
  path: PropTypes.string,
  // required array formate which is array of strings i.e ["key1", "key2", ...]
};

// Set multiple cookies.
const setBulkItems = (keyValArrayOfObjects = []) => {
  return keyValArrayOfObjects.map(({ key, value, exp = 1, path = "/" }) =>
    setItem(key, value, exp, path)
  );
};

setBulkItems.propTypes = {
  keyValArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// Remove particular single cookie.
const removeItem = (key, path = "/") => {
  const secureField = ["exp", "sessionToken", "token", "workspace"]
  if (checkRefLogin()) {
    return jsCookie.remove("refSecureToken", { path })
  }
  if(key === "token"){
    localStorage.removeItem("token");
    return;
  }
  if(secureField.includes(key)) {
    jsCookie.remove("secureToken", { path });
  }
  jsCookie.remove(key, { path });
  return;
};
removeItem.propTypes = {
  key: PropTypes.string.isRequired,
  path: PropTypes.string,
};

// Remove miltiple cookies.
const removeBulkItems = (keyValArrayOfObjects = []) => {
  return keyValArrayOfObjects.map(({ key, path = "/" }) =>
    removeItem(key, path)
  );
};
removeBulkItems.propTypes = {
  keyValArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export {
  getItem,
  getBulkItems,
  setItem,
  setBulkItems,
  removeItem,
  removeBulkItems,
};
