import { combineReducers } from '@reduxjs/toolkit';
import dashboardReducer from "./dashboardReducer";
import walletReducer from "./walletReducer";
import workspaceReducer from './workspaceReducer';
import notificationReducer from './notificationReducer';

const rootReducer = combineReducers({
    dashboard: dashboardReducer,
    wallet: walletReducer,
    workspace: workspaceReducer,
    notification: notificationReducer,
});

export default rootReducer;