const FullscreenIcon = ({ color = "white", width = "25", height = "24" }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="24"
        >
          <rect x="0" y="0" width="25" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0)">
          <path
            d="M3 21V16H5V19H8V21H3ZM16 21V19H19V16H21V21H16ZM3 8V3H8V5H5V8H3ZM19 8V5H16V3H21V8H19Z"
            fill={color} // Dynamic color prop
          />
        </g>
      </svg>
    );
  };
  
  export default FullscreenIcon;
  