import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosGetCall, axiosPostCall } from "../../services/apisCall";

export const updateWorkspace = (data) => ({
    type: "UPDATE_WORKSPACE",
    payload: data,
});

export const setWorkspaces = (data) => ({
    type: "SET_WORKSPACES",
    payload: data,
});

export const appendWorkspace = (newWorkspace) => ({
  type: "APPEND_WORKSPACE",
  payload: newWorkspace,
});

export const fetchWorkspaces = createAsyncThunk(
  "workspace/fetchWorkspaces",
  async () => {
    const response = await axiosGetCall("fetchWorkspace"); // Replace with your API endpoint
    return response.data;
  }
);

// Add a new workspace
export const addWorkspace = createAsyncThunk(
  "workspace/addWorkspace",
  async (newWorkspace) => {
    const response = await axiosPostCall("createWorkspace", newWorkspace); // Replace with your API endpoint
    return response.data;
  }
);