const LOCAL_STORAGE = {
    BRANDING: 'branding'
}
const DB_NAME = "mail-db";
const DB_VERSION = 1; // Use a long long for this value (don't use a float)
const DB_MAIN_DATA_STORE_NAME = 'all';
const DB_COUNTERS_STORE_NAME = 'counts';

export {
    LOCAL_STORAGE,
    DB_NAME,
    DB_VERSION,
    DB_MAIN_DATA_STORE_NAME,
    DB_COUNTERS_STORE_NAME
}