import { initializeApp } from "firebase/app";
import { getToken, onMessage } from "firebase/messaging";
import { configFile } from '../config';
import { axiosPostCall } from '../services/apisCall';

const { firebaseConfig, VAPIDKEY } = configFile;

const app = initializeApp(firebaseConfig);
let messaging = {};

if ("serviceWorker" in navigator && "PushManager" in window) {
  import("firebase/messaging").then(({ getMessaging }) => {
    messaging = getMessaging(app);
    console.log("Firebase Messaging initialized.");
  }).catch((error) => {
    console.warn("⚠️ Firebase Messaging initialization failed:", error);
  });
} else {
  console.warn("⚠️ Push notifications are not supported in this environment.");
}
const generateNotificationToken = async () => {
    try {  
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey: VAPIDKEY
            });
            console.log(token);
            await axiosPostCall('insertFirebaseToken', { token });
        }
    } catch (error) {
        console.error(error);
    }
}

export { messaging, getToken, onMessage, generateNotificationToken };