import React, { useState, useEffect } from "react";
import { handleLogout } from "../services/apisCall";
import Logger from "../lib/Logger";
import { removeBulkItems, removeItem } from "../lib/cookies";
import { deleteIndexedDB } from "../services/db/db";
import { getAllDecodedSecureToken } from "../utils/common";
import { DB_NAME } from "../config/enums";
const logger = new Logger("logout");

const Logout = () => {
    const [, setIsLoading] = useState(true); // State to track loading
    const logout = async () => {
        try {
            await handleLogout(); // API call for logout
            const allToken = getAllDecodedSecureToken();
            allToken.forEach(async ({email}) => {
                await deleteIndexedDB(`${email}_${DB_NAME}`)
            })
            if (window.self === window.top) {
                removeBulkItems([{ key: "sessionToken" }, { key: "token" }, { key: "exp" }, { key: "secureToken" }]);
                removeItem("secureToken");
                localStorage.removeItem("branding");
                window.location.href = "/login";
            } else {
                window.parent.postMessage({message: "LOGOUT", redirectPath: `https://${window.location.hostname}/login`})
            }
        } catch (error) {
            logger.error("Logout.jsx [logout] catchError", error);
        }
    }

    useEffect(() => {
        logout();
        setTimeout(() => setIsLoading(false), 3000); // Simulate delay for demo purposes
    }, []);

    return (
        <>
        </>
    );
}

export default Logout;
