import { DB_NAME, DB_VERSION, DB_MAIN_DATA_STORE_NAME, DB_COUNTERS_STORE_NAME } from "../../config/enums";

const getStoreConn = (db, storeName) => {
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    return store;
}

const connectIndexDb = (userId) => {
    return new Promise((resolve, reject) => {
        try {
            let isNewSetup = false;
            const req = window.indexedDB.open(`${userId}_${DB_NAME}`, DB_VERSION);
            req.onsuccess = function (evt) {
                const db = evt.target.result;
                return resolve({ db, isNewSetup });
            };
            req.onerror = function (evt) {
                console.error("connectIndexDb:", evt.target.errorCode);
                reject(evt.target.errorCode)
            };
            req.onupgradeneeded = function (evt) {
                console.log("connectIndexDb.onupgradeneeded");
                isNewSetup = true
                const store = evt.currentTarget.result.createObjectStore(DB_MAIN_DATA_STORE_NAME, { keyPath: 'id', autoIncrement: true });
                store.createIndex('seqid', 'seqid', { unique: true });
                // store.createIndex('subject', 'subject', { unique: false });
                // store.createIndex('from', 'from', { unique: false });
                // store.createIndex('to', 'to', { unique: false });
                // store.createIndex('date', 'date', { unique: false });
                store.createIndex('data', 'data', { unique: false });
                const countStore = evt.currentTarget.result.createObjectStore(DB_COUNTERS_STORE_NAME, { keyPath: 'id', autoIncrement: true });
                countStore.createIndex('name', 'name', { unique: true });
                countStore.createIndex('count', 'count', { unique: false });
                countStore.createIndex('aditionalData', 'aditionalData', { unique: false });
                countStore.createIndex('updatedAt', 'updatedAt', { unique: false });
                // const store = getStoreConn(db, DB_MAIN_DATA_STORE_NAME);
                countStore.add({ name: "", count: 0, aditionalData: {}, updatedAt: 0 })
            };
        } catch (error) {
            reject(error)
        }
    })
}

const processAllIndexDbActions = () => {

}

const insertBulkDataToAll = (db, data) => {
    try {
        const store = getStoreConn(db, DB_MAIN_DATA_STORE_NAME);
        if (Object.entries(data).length) {
            for (const [key, value] of Object.entries(data)) {
                store.add({ seqid: key, data: value })
            }
        }
        return true;
    } catch (error) {
        throw new Error(error)
    }
}

const updateLatestMailDate = (db, date) => {
    try {
        console.log("updateLatestMailDate ...")
        const store = getStoreConn(db, DB_COUNTERS_STORE_NAME);
        const req = store.openCursor();
        req.onerror = function (event) {
            console.log("Error: ", event);
        };
        req.onsuccess = function (event) {
            const cursor = event.target.result;
            if (cursor) {
                let data = cursor.value;
                data.updatedAt = date;
                const res = cursor.update(data);

                res.onsuccess = function () {
                    console.log("update success!!");
                }
                res.onerror = function () {
                    console.log("update failed!!");
                }
                cursor.continue();
            }
        }

        return true;

    } catch (error) {
        throw new Error(error)
    }
}

const updateAllData = (db, data, id) => {
    return new Promise((resolve, reject) => {
        try {
            const store = getStoreConn(db, DB_MAIN_DATA_STORE_NAME);

            const req = store.openCursor();
            req.onerror = function (event) {
                console.log("Error: ", event);
                resolve(false);
            };

            req.onsuccess = function (event) {
                const cursor = event.target.result;
                if (cursor) {

                    if (cursor.value?.seqid === 0) {
                        cursor.delete();
                    }

                    // checking the current seqid match the thrid id.
                    if (cursor.value?.seqid === id) {

                        let cursorData = cursor.value;
                        cursorData.data = { ...data };
                        cursorData.seqid = id;

                        const res = cursor.delete();

                        res.onsuccess = function () {
                            store.add({ seqid: id, data: cursorData?.data });
                            console.log("new thrid update success!!");
                            resolve(true);
                        }
                        res.onerror = function () {
                            console.log("new thrid update failed!!");
                            resolve(false);
                        }
                    }
                    cursor.continue();
                }
            }
            resolve(false);

        } catch (error) {
            console.log(error)
            reject(false);
        }
    })
}

const fetchInboxData = (db) => {
    return new Promise((resolve, reject) => {
        try {
            const transaction = db.transaction([DB_MAIN_DATA_STORE_NAME])
            const object_store = transaction.objectStore(DB_MAIN_DATA_STORE_NAME);
            const request = object_store.openCursor();
            // const index = object_store.index("seqid");
            // const request = index.getAllKeys();
            request.onerror = function (event) {
                console.err("error fetching data", event);
                reject(event)
            };

            let data = {};
            request.onsuccess = function (event) {
                let cursor = event.target.result;
                if (cursor) {
                    // let key = cursor.primaryKey;
                    let value = cursor.value;

                    data[value?.seqid] = value?.data;
                    cursor.continue();
                }
            };

            transaction.oncomplete = function () {
                resolve(data)
            };
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

const fetchSortedInboxData = (db) => {
    return new Promise((resolve, reject) => {
        try {
            const transaction = db.transaction([DB_MAIN_DATA_STORE_NAME])
            const object_store = transaction.objectStore(DB_MAIN_DATA_STORE_NAME);
            const request = object_store.openCursor();
            // const index = object_store.index("seqid");
            // const request = index.getAllKeys();
            request.onerror = function (event) {
                console.err("error fetching data", event);
                reject(event)
            };

            let data = {};
            request.onsuccess = function (event) {
                let cursor = event.target.result;
                if (cursor) {
                    // let key = cursor.primaryKey;
                    let value = cursor.value;

                    data[value?.seqid] = value?.data;
                    cursor.continue();
                }
            };

            transaction.oncomplete = function () {
                const allKeys = Object.keys(Object.fromEntries(
                    Object.entries(data)
                        .sort(([, a], [, b]) => new Date(b.updatedAt) - new Date(a.updatedAt)),
                )) || [];
                const sortedData = {};
                allKeys.forEach((key) => {
                    sortedData[key] = data[key];
                })
                resolve(sortedData)
            };
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}

const fetchInboxDataByIds = (db, seqIds = []) => {
    return new Promise((resolve, reject) => {
        try {
            const transaction = db.transaction([DB_MAIN_DATA_STORE_NAME])
            const object_store = transaction.objectStore(DB_MAIN_DATA_STORE_NAME);
            const request = object_store.openCursor();

            const data = {};
            const presentIds = [];

            request.onerror = function (event) {
                console.err("error fetching data", event);
                reject(event)
            };

            request.onsuccess = function (event) {
                let cursor = event.target.result;
                if (cursor) {
                    presentIds.push(cursor.value?.seqid);
                    if (seqIds.includes(cursor.value?.seqid)) {
                        let value = cursor.value;
                        data[value?.seqid] = value?.data;
                    }
                    cursor.continue();
                }
            };

            transaction.oncomplete = function () {
                const missingSeqIds = seqIds.filter(id => !new Set(presentIds).has(id));
                resolve({ data, missingSeqIds: missingSeqIds?.length > 0 ? missingSeqIds : [] })
            };

        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};


const getInboxDataCount = (db) => {
    return new Promise((resolve, reject) => {
        try {
            const transaction = db.transaction([DB_MAIN_DATA_STORE_NAME])
            const store = transaction.objectStore(DB_MAIN_DATA_STORE_NAME);
            // Count records in the store
            const countRequest = store.count();

            countRequest.onsuccess = () => {
                resolve(countRequest?.result || 0);
            };

            countRequest.onerror = (event) => {
                console.error("Error counting data in store:", event.target.error);
                reject(event.target.error);
            };
        } catch (error) {
            console.log(error);
            reject(error)
        }
    })
}

const fetchTotalCountsIndexDb = (db) => {
    return new Promise((resolve, reject) => {

        try {
            const transaction = db.transaction([DB_COUNTERS_STORE_NAME])
            const object_store = transaction.objectStore(DB_COUNTERS_STORE_NAME);
            const request = object_store.openCursor();

            request.onerror = function (event) {
                console.err("error fetching data", event);
                reject(event)
            };

            let data = {};
            request.onsuccess = function (event) {
                let cursor = event.target.result;
                if (cursor) {
                    let value = cursor.value;

                    data = value;

                    cursor.continue();
                }
            };

            transaction.oncomplete = function () {
                resolve(data)
            };
        } catch (error) {
            reject(error)
        }
    })
}

const addNewMailToIDb = async (db, data, id) => {

    const store = getStoreConn(db, DB_MAIN_DATA_STORE_NAME);

    const isExisting = await updateAllData(db, data, id);
    if (!isExisting) {
        try {
            await store.add({ seqid: id, data });
        } catch (error) {
            console.error("Failed to add new mail:", error);
            return false;
        }
    }
    return true;
};


const updateAttributs = (db, data) => {
    try {
        console.log("calling")
        const store = getStoreConn(db, DB_MAIN_DATA_STORE_NAME);

        const req = store.openCursor();
        req.onerror = function (event) {
            console.log("Error: ", event);
            return false;
        };

        req.onsuccess = function (event) {
            const cursor = event.target.result;
            if (cursor) {

                // checking the current seqid match the thrid id.
                if (cursor.value?.seqid === data?.id) {

                    let cursorData = cursor.value;

                    const oldFlages = cursorData?.data?.attributes?.flags || [];
                    if (data.method === 'add') {
                        cursorData.data.attributes.flags = [...oldFlages, data?.newFlag]
                    } else {
                        // if (data?.newFlag === UNSEEN) {
                        //     const removedFlages = oldFlages.filter(flag => flag !== SEEN);
                        //     cursorData.data.attributes.flags = [...removedFlages]
                        // } else {
                        const removedFlages = oldFlages.filter(flag => flag !== data?.newFlag);
                        cursorData.data.attributes.flags = [...removedFlages]
                        // }
                    }

                    const res = cursor.delete();

                    res.onsuccess = function () {
                        store.add({ seqid: cursorData?.data?.id, data: cursorData?.data })
                        console.log("flag update success!!");
                    }
                    res.onerror = function () {
                        console.log("flag update failed!!");
                    }
                }
                cursor.continue();
            }
        }
        return true;

    } catch (error) {
        throw new Error(error)
    }
}

// Marking as \\seen in attributs 
// const markAllReadIdb = (db, id) => {
//     return new Promise((resolve, reject) => {
//         const store = getStoreConn(db, DB_MAIN_DATA_STORE_NAME);

//         const request = store.openCursor();

//         request.onsuccess = (event) => {
//             const cursor = event.target.result;

//             if (cursor) {
//                 const object = cursor.value;

//                 // mark all for thrid
//                 if (id) {
//                     if (object?.data?.id === id) {
//                         const thrid = object?.data?.thridData;

//                         Object.keys(thrid).forEach((key) => {
//                             thrid[key].attributes.flags.push(SEEN);
//                         });

//                         object?.data?.attributes?.flags.push(SEEN);
//                         object.data.thridData = thrid;

//                         cursor.update(object);
//                     }
//                 } else {
//                     // Mark all read for list
//                     if (!object?.data?.attributes?.flags.includes(SEEN)) {

//                         object?.data?.attributes?.flags.push(SEEN);

//                         // Updating the value in object as seen
//                         cursor.update(object);
//                     }
//                 }

//                 cursor.continue();
//             } else {
//                 // No more entries, resolve the promise
//                 resolve();
//             }
//         };

//         request.onerror = (event) => {
//             reject(event.target.errorCode);
//         };
//     });
// };


// Performing action on complete row for update
const updateCompleteRecordINIdb = async (db, completeData) => {
    return new Promise((resolve, reject) => {
        const store = getStoreConn(db, DB_MAIN_DATA_STORE_NAME);

        const request = store.openCursor();

        request.onsuccess = (event) => {
            const cursor = event.target.result;

            if (cursor) {
                const object = cursor.value;

                const id = completeData?.thridId;
                const dbId = object?.data?.thridId;

                if (id === dbId) {
                    object.data = JSON.parse(JSON.stringify(completeData));
                    console.log(object.data)
                    cursor.update(object);
                }
                cursor.continue();
            } else {
                // No more entries, resolve the promise
                resolve();
            }
        };

        request.onerror = (event) => {
            reject(event.target.errorCode);
        };
    });
};

// Performing action on complete row for remove
const removeCompleteRecordINIdb = async (db, id) => {
    return new Promise((resolve, reject) => {
        const store = getStoreConn(db, DB_MAIN_DATA_STORE_NAME);

        const request = store.openCursor();

        request.onsuccess = (event) => {
            const cursor = event.target.result;

            if (cursor) {
                const object = cursor.value;

                const dbId = object?.data?.thridId;

                if (id === dbId) {
                    cursor.delete();
                }
                cursor.continue();
            } else {
                // No more entries, resolve the promise
                resolve();
            }
        };

        request.onerror = (event) => {
            reject(event.target.errorCode);
        };
    });
};

// Performing action on complete row for get deta
const fetchCompleteRecordINIdb = async (db, id) => {
    return new Promise((resolve, reject) => {
        const store = getStoreConn(db, DB_MAIN_DATA_STORE_NAME);

        const request = store.openCursor();

        request.onsuccess = (event) => {
            const cursor = event.target.result;

            if (cursor) {
                const object = cursor.value;

                const dbId = object?.data?.thridId;

                if (id === dbId) {
                    console.log(object.data);
                    return resolve(object.data)
                }
                cursor.continue();
            } else {
                // No more entries, resolve the promise
                resolve();
            }
        };

        request.onerror = (event) => {
            reject(event.target.errorCode);
        };
    });
};

const fetchCustomRecordsINIdb = async (db, ids) => {
    return new Promise((resolve, reject) => {
        const store = getStoreConn(db, DB_MAIN_DATA_STORE_NAME);

        const request = store.openCursor();

        const results = {};
        const idSet = new Set(ids);

        request.onsuccess = (event) => {
            const cursor = event.target.result;

            if (cursor) {
                const object = cursor.value;

                const dbId = object?.data?.thridId;

                if (idSet.has(dbId)) {
                    results[dbId] = object.data;
                }

                cursor.continue();
            } else {
                resolve(results);
            }
        };

        request.onerror = (event) => {
            reject(event.target.errorCode);
        };
    });
};

const deleteIndexedDB = (dbName) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.deleteDatabase(dbName);

        request.onsuccess = () => {
            console.log(`Database ${dbName} deleted successfully`);
            resolve();
        };

        request.onerror = (event) => {
            console.error(`Error deleting database ${dbName}:`, event.target.error);
            reject(event.target.error);
        };

        request.onblocked = () => {
            console.warn(`Deletion of database ${dbName} is blocked. Close all connections.`);
        };
    });
};


export {
    connectIndexDb,
    processAllIndexDbActions,
    insertBulkDataToAll,
    fetchInboxData,
    fetchInboxDataByIds,
    updateLatestMailDate,
    addNewMailToIDb,
    fetchTotalCountsIndexDb,
    updateAttributs,
    // markAllReadIdb,
    updateCompleteRecordINIdb,
    removeCompleteRecordINIdb,
    fetchCompleteRecordINIdb,
    fetchCustomRecordsINIdb,
    getInboxDataCount,
    deleteIndexedDB,
    fetchSortedInboxData,
}
