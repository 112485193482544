import * as React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AccountTabPanel from './accountTabPanel';
import KycTabPanel from './kycTabPanel';
import AppearanceTabPanel from './appearanceTabPanel';
import NotificationTabPanel from './notificationTabPanel';
import WalletTabPanel from './walletTabPanel';
import BroadcastSetting from './broadcastPanel';
import LoggedInDevicesTabPanel from './loggedInTabPanel'; 
import { isMainUser, isEmployeeAccount } from '../../utils/common';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HeadingTabPanel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [value, setValue] = React.useState(location?.state || 0);

  const tab = searchParams.get('tab');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/settings?tab=${newValue}`, { replace: true });
  };

  React.useEffect(() => {
    if (tab) {
      setValue(parseInt(tab));
    }

  }, [tab])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ pl: '20px' }}>
          <Tab label="Account" {...a11yProps(0)} />
          <Tab label="Logged in devices" {...a11yProps(1)} />
          <Tab label="Appearance" {...a11yProps(2)} />
          <Tab label="Wallet" {...a11yProps(3)} />
          <Tab label="Notification" {...a11yProps(4)} />
          {!isEmployeeAccount() && <Tab label="KYC" {...a11yProps(5)} />}
          {isMainUser() && <Tab label="Voice Settings" {...a11yProps(6)} />}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <AccountTabPanel />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <LoggedInDevicesTabPanel />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <AppearanceTabPanel />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <WalletTabPanel />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <NotificationTabPanel />
      </CustomTabPanel>
      {!isEmployeeAccount() &&
      <CustomTabPanel value={value} index={5}>
          <KycTabPanel />
        </CustomTabPanel>}
      {isMainUser() && <CustomTabPanel value={value} index={6}>
        <BroadcastSetting />
      </CustomTabPanel>}

    </Box>
  );
}

export default HeadingTabPanel;