import { useEffect } from 'react';
import parse from 'url-parse';
// import { setBulkItems } from '../lib/cookies';
import { setSecureLoginSession } from '../utils/common';
import { getItem } from '../lib/cookies';

const CustomSession = () => {
  useEffect(() => {
    const url = parse(window.location.href, true);
    const { token, sessionToken, exp, redirectTo = "mail" } = url.query;
    if (!token || !sessionToken || !exp) return;
    const secureToken = getItem("secureToken");
    let accountId = 0;

    const indexes = (secureToken ? JSON.parse(secureToken) : []).findIndex((ele) => ele?.sessionToken === sessionToken);
    if(indexes !== -1) {
      debugger;
      window.location.href = `https://${window.location.host}/${redirectTo}/u/${indexes}`
      console.log("🚀 ~ useEffect ~ window.location.href:", window.location.href)
      return;
    }
    if (secureToken && secureToken.length > 0) {
      setSecureLoginSession(exp, sessionToken, token, "", JSON.parse(secureToken).length + 1)
      accountId = JSON.parse(secureToken).length + 1
    }
    else {
      setSecureLoginSession(exp, sessionToken, token, "", 0)
    }

    window.location.href = `https://${window.location.host}/${redirectTo}/u/${accountId}`
    debugger

  }, []);
  return (<> </>)
}

export default CustomSession;

