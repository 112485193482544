// ProfileMenu.js
import React, { useEffect, useRef, useState } from 'react';
import { List, ListItemButton, Box, Avatar, Typography, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import AddLargeLineIcon from "../assets/svgs/icons/add-large-line";
// import MoonLineIcon from "../../assets/svgs/icons/moon"; import ArrowDownSFillIcon from "../assets/svgs/icons/fill/arrow-down-s-fill"; import SuitcaseLineIcon from "../assets/svgs/icons/suitcase-line"; import GroupLineIcon from "../assets/svgs/icons/group-line";
import UserLineIcon from "../assets/svgs/icons/user-line";
import NewsLineIcon from "../assets/svgs/icons/news-line";
import LogoutCircleLineIcon from "../assets/svgs/icons/logout-circle-line";


import { checkPermission, getAllDecodedSecureToken, getCurrentAccountId, decodeToken, isEmployeeAccount, getCurrentPath } from '../utils/common';
import { axiosPostCallWithStatusCode } from '../services/apisCall';
import Logger from '../lib/Logger';
const logger = new Logger("addAccount");

const ProfileMenuAccount = () => {

    const jwtInfo = checkPermission(true);
    const secureToken = getAllDecodedSecureToken();
    const listRef = useRef();

    const handleAccountSwitch = async (accountId, parentUserId, parentSession, index) => {
        try {
            // const token = decodeToken();
            await axiosPostCallWithStatusCode(
                "switchAccount",
                { accountId, parentId: parentUserId, parentSession },
            );
            window.open(`/u/${accountId || 0}`, '_blank');
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.warn('Account not found, redirecting to account 0');
                window.location.href = "/u/0"
            } else {
                logger.error('Unexpected error during account switch.', error);
            }
        }
    }
    // const token = decodeToken();
    // const { permission } = jwtInfo;
    const [anchorElProfile, setAnchorElProfile] = useState(true);
    useEffect(() => {
        window.parent.postMessage({action: "initialHeight", height: listRef.current.offsetHeight })
    }, [])

    // const handleClickProfile = (event) => {
    //     setAnchorElProfile(event.currentTarget);
    // };


    return (
        <List
            ref={listRef}
            sx={{
                paddingTop: "0px",
                paddingBottom: "0px",
                border: "1px solid",
                borderColor: "grey.300",
                overflow: "hidden",
                borderRadius: "10px",
                backgroundColor: "card.main",
            }}
        >
            <ListItemButton
                sx={{ borderBottom: "1px solid", borderColor: "grey.300" }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                        width: "100%",
                        minWidth: "230px",
                    }}
                >
                    <Avatar
                        src={jwtInfo?.profileUrl}
                        sx={{
                            width: "44px",
                            height: "44px",
                            mb: "12px",
                        }}
                    ></Avatar>

                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "grey.600",
                            textAlign: "center",
                        }}
                    >
                        Hi, {jwtInfo?.name || "User"}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "13px",
                            fontWeight: 400,
                            color: "grey.500",
                            textAlign: "center",
                        }}
                    >
                        {jwtInfo?.email || ""}
                    </Typography>
                </Box>
            </ListItemButton>

            {
                secureToken.map(({ name, email, accountId, parentUserId, parentSession, profileUrl }, index) => (
                    accountId !== getCurrentAccountId() && <ListItemButton
                        key={accountId}
                        sx={{ borderBottom: "1px solid", borderColor: "grey.300" }}
                        onClick={() => {
                            handleAccountSwitch(accountId, parentUserId, parentSession, index);
                            setAnchorElProfile(null);
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: "42px" }}>
                            <Avatar
                                src={profileUrl}
                                sx={{
                                    width: "30px",
                                    height: "30px",
                                }}
                            ></Avatar>
                        </ListItemIcon>
                        <ListItemText
                            sx={{ minWidth: "200px" }}
                            primary={
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        color: "grey.600",
                                    }}
                                >
                                    {name}
                                </Typography>
                            }
                            secondary={email}
                        />
                    </ListItemButton>
                ))
            }
            { <ListItemButton
                sx={{ borderBottom: "1px solid", borderColor: "grey.300" }}
                onClick={() => {
                    const { parentUserId, parentSession } = decodeToken(true);
                    const message = { action: "open", path: `/authLogin?authUser=${parentUserId}&authSession=${parentSession}` };
                    window.parent.postMessage(message, '*');
            
                    const heightMessage = { action: "adjustHeight", height: 33 };
                    window.parent.postMessage(heightMessage, '*');
                    setAnchorElProfile(null);
                }}
            >
                <ListItemIcon sx={{ minWidth: "30px" }}>
                    <SvgIcon
                        sx={{
                            width: "14px",
                            height: "14px",
                        }}
                    >
                        <AddLargeLineIcon color={"currentColor"} />
                    </SvgIcon>
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "grey.600",
                            }}
                        >
                            Add another account
                        </Typography>
                    }
                />
            </ListItemButton>}

            <ListItemButton
                sx={{ borderBottom: "1px solid", borderColor: "grey.300" }}
                onClick={() => {
                    window.parent.postMessage({ action: "redirect", path: "/settings" })
                    setAnchorElProfile(null);
                }}
            >
                <ListItemIcon sx={{ minWidth: "30px" }}>
                    <SvgIcon
                        sx={{
                            width: "18px",
                            height: "18px",
                        }}
                    >
                        <UserLineIcon color={"currentColor"} />
                    </SvgIcon>
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "grey.600",
                            }}
                        >
                            My Account
                        </Typography>
                    }
                />
            </ListItemButton>
            {!isEmployeeAccount() && (
            <ListItemButton
                sx={{ borderBottom: "1px solid", borderColor: "grey.300" }}
                onClick={() => {
                    window.parent.postMessage({ action: "redirect", path: "/settings?tab=5" })
                    setAnchorElProfile(null);
                }}
            >
                <ListItemIcon sx={{ minWidth: "30px" }}>
                    <SvgIcon
                        sx={{
                            width: "18px",
                            height: "18px",
                        }}
                    >
                        <NewsLineIcon color={"currentColor"} />
                    </SvgIcon>
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "grey.600",
                            }}
                        >
                            KYC
                        </Typography>
                    }
                />
            </ListItemButton>)}
            <ListItemButton
                onClick={() => {
                    window.parent.postMessage({ action: "redirect", path: "/logout" })
                    setAnchorElProfile(null);
                }}
            >
                <ListItemIcon sx={{ minWidth: "30px" }}>
                    <SvgIcon
                        sx={{
                            width: "18px",
                            height: "18px",
                        }}
                    >
                        <LogoutCircleLineIcon color={"currentColor"} />
                    </SvgIcon>
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "grey.600",
                            }}
                        >
                            Logout
                        </Typography>
                    }
                />
            </ListItemButton>
        </List>
    );
};

export default ProfileMenuAccount;