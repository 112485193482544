const initialState = {
    notifications: [], 
    count: 0,
  };
  
  const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_NOTIFICATIONS":
        return {
          ...state,
          notifications: action.payload.notifications,
          count: action.payload.count,
        };
      case "CLEAR_NOTIFICATIONS":
        return {
          ...state,
          notifications: [],
          count: 0,
        };
      default:
        return state;
    }
  };
  
  export default notificationReducer;