// MainComponent.js
import React, { useEffect, useState, useRef } from 'react';
import { IconButton, Avatar, Popover } from '@mui/material';
import { fetchRefLoginPath, getCurrentPath } from '../../utils/common';

const AddAccountPopoverSection = () => {
    const [anchorElProfile, setAnchorElProfile] = useState(null);
    const iframeRef = useRef(null);
    const [iframeHeight, setIframeHeight] = useState(400); 

    const handleProfileMenuOpen = (event) => {
        setAnchorElProfile(event.currentTarget);
    };
    console.log(`${fetchRefLoginPath()}/u/0/profileMenu`)

    const handleProfileMenuClose = () => {
        setAnchorElProfile(null);
    };
    const openProfile = Boolean(anchorElProfile);
    const idProfile = openProfile ? "simple-popover-profile" : undefined;

    useEffect(() => {
        const handleMessage = (event) => {
            const eventHost = new URL(event.origin).hostname;
            if (eventHost === window.location.hostname) {
                const { action, path, height } = event.data;
                if (action === "redirect") {
                    window.location.href = `${fetchRefLoginPath()}${getCurrentPath()}${path}`;
                    return;
                }
                if (action === "open") {
                    window.open(path, '_blank');
                }

                if(action === "initialHeight") {
                    setIframeHeight(height);
                }
                // if (action === "adjustHeight" && iframeRef.current) {
                //     // Update the iframe height by adding the specified height increment
                //     console.log("Adjusting iframe height by:", height);
                //     setIframeHeight((prevHeight) => prevHeight + height);
                // }
            }
        };
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div>
            <IconButton onClick={handleProfileMenuOpen}>
                <Avatar src="/path/to/profile/image" />
            </IconButton>
            {anchorElProfile &&
                (<Popover
                    id={idProfile}
                    open={openProfile}
                    anchorEl={anchorElProfile}
                    onClose={handleProfileMenuClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            border: 'none',
                        },
                    }}
                >
                    <iframe
                        ref={iframeRef}
                        title='addAccount'
                        src={`https://dev-console.sarv.com/u/0/profileMenu`}
                        style={{
                            // position: 'absolute',
                            // top: '50px',
                            // right: '10px',
                            width: '250px',
                            height: `${iframeHeight}px`, // Use dynamic height
                            border: 'none',
                            zIndex: 1000,
                        }}
                    />
                </Popover>)
            }
        </div>
    );
};

export default AddAccountPopoverSection;