import React from 'react';
import { Box, Grid, Typography, Stack } from '@mui/material';

export default function FileCardGrid({ files, menuItems, onUpdate, currentDir, currentUID,onClick }) {
    return (
        <Grid container spacing={1.2} sx={{ height: "295px", overflow: "auto", p: '10px' }}>
            {files.map((file, index) => (
                <Grid item xs={3} key={index}>
                    <FileCard
                        file={file}
                        menuItems={menuItems}
                        onUpdate={onUpdate}
                        currentDir={currentDir}
                        currentUID={currentUID}
                        onClick={onClick}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

function FileCard({ file, menuItems, onUpdate, currentDir, currentUID, onClick }) {
    return (
        <Box
            className="folder-card"
            onClick={() => onClick(file)}
            sx={{
                backgroundColor: 'grey.200',
                borderRadius: '6px',
                p: '12px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'space-between',
                flexDirection: 'column',
                cursor: 'pointer', // Add pointer cursor for better UX
                '&:hover': {
                    backgroundColor: 'grey.300', // Add hover effect
                }
            }}
        >
            {file?.signed_url !== '' ? (
                <img
                    src={file?.signed_url}
                    alt={file?.originalName}
                    style={{
                        width: '100%',
                        height: '160px',
                        objectFit: 'cover',
                    }}
                />
            ) : (
                <Box
                    sx={{
                        backgroundColor: 'grey.300',
                        height: '160px',
                        width: '100%',
                        borderRadius: '6px',
                    }}
                ></Box>
            )}
            <Stack
                item
                direction="row"
                spacing={1}
                justifyContent={'space-between'}
                width={'100%'}
                alignItems={'center'}
            >
                <Typography
                    onClick={() => {
                        if (!menuItems.includes('restore')) {
                            // handleFileOpen(file);
                        }
                    }}
                    sx={{
                        fontSize: '14px',
                        fontWeight: '500',
                        color: 'grey.800',
                        paddingLeft: '8px',
                        cursor: 'pointer',
                    }}
                >
                    {file.originalName}
                </Typography>
            </Stack>
        </Box>
    );
}