import React, { useEffect, useState } from 'react';
import CustomModal from '../../components/modal/customModal';
import { Box, Button, styled, Typography, Skeleton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { axiosGetCall } from '../../services/apisCall';
// import moment from 'moment';
import Logger from '../../lib/Logger';

const logger = new Logger("loggedInDevices");

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.grey[1000],
    color: theme.palette.grey[600],
    '&:hover': {
        color: theme.palette.grey[600],
    }
}));

const columns = [
    { id: 'id', label: 'S NO', minWidth: 100, align: "center" },
    { id: 'device', label: 'Device', minWidth: 300, align: "center" },
    { id: 'location', label: 'Location', minWidth: 200, align: "center" },
    // { id: 'lastActive', label: 'Last Active', minWidth: 200, align: "center" },
    { id: 'status', label: 'Status', minWidth: 100, align: "center" },
];

const LoggedInDevices = ({ openLoggedInDevices, handleClose, setOpenLoggedInDevices, email }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [devices, setDevices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [load, setLoad] = useState(false);
    const [currentSessionId, setCurrentSessionId] = useState(null);

    const fetchLoggedInDevices = async () => {
        try {
            setIsLoading(true);
            const params = {
                email: email,
                skip: page * rowsPerPage,
                limit: rowsPerPage
            };

            const response = await axiosGetCall("loggedInDevices", params);
            
            if (response && Array.isArray(response.data)) {
                setDevices(response.data);
                setCount(response.totalCount || response.data.length);
                
                // Find current session
                const currentSession = response.data.find(device => device.isCurrent);
                if (currentSession?.session) {
                    setCurrentSessionId(currentSession.session);
                }
            } else {
                throw new Error("Invalid response format");
            }
        } catch (error) {
            logger.error("Failed to fetch devices:", error);
            setDevices([]);
            setCount(0);
        } finally {
            setIsLoading(false);
        }
    };

    const formatDeviceInfo = (device) => {
        const deviceInfo = device.device || {};
        const locationInfo = device.location || {};
        
        return {
            id: device.id,
            device: `${deviceInfo.device || 'Unknown'} (${deviceInfo.browser || 'Unknown'}, ${deviceInfo.os || 'Unknown OS'})`,
            location: locationInfo.city && locationInfo.country 
                ? `${locationInfo.city}, ${locationInfo.country}`
                : locationInfo.ip || 'Unknown',
            // lastActive: device.lastActive ? moment(device.lastActive).format('D MMM, YYYY hh:mm A') : 'Unknown',
            isCurrent: device.session === currentSessionId
        };
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setLoad(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setLoad(true);
    };

    const forceActions = () => (
        <Box sx={{
            mt: 3,
            mb: -2,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            justifyContent: 'flex-end'
        }}>
            <Button
                variant='outlined'
                size='small'
                onClick={() => {
                    setOpenLoggedInDevices(false);
                    handleClose(false);
                }}
            >
                Close
            </Button>
        </Box>
    );

    useEffect(() => {
        fetchLoggedInDevices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (load) {
            fetchLoggedInDevices();
            setLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load]);

    const description = (
        <Box sx={{
            mt: 1.3,
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            width: '100%',
        }}>
            <Paper sx={{ width: '100%', borderRadius: '6px' }}>
                <TableContainer sx={{
                    maxHeight: window.innerHeight - 225
                }}>
                    <Table stickyHeader aria-label="sticky table" size='small'>
                        <TableHead sx={{
                            backgroundColor: 'grey.1000',
                            borderTop: '1px solid',
                            borderBottom: '1px solid',
                            borderColor: 'grey.900'
                        }}>
                            <TableRow>
                                {columns.map((column) => (
                                    <CustomTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </CustomTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {devices.length > 0 && devices.map((device, index) => {
                                const formattedDevice = formatDeviceInfo(device);
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={device.id || index}>
                                        <TableCell align="center" sx={{ color: 'grey.600', fontWeight: 500 }}>
                                            {index + 1 + (page * rowsPerPage)}
                                        </TableCell>
                                        <TableCell align="center" sx={{ color: 'grey.600', fontWeight: 500 }}>
                                            {formattedDevice.device}
                                        </TableCell>
                                        <TableCell align="center" sx={{ color: 'grey.600', fontWeight: 500 }}>
                                            {formattedDevice.location}
                                        </TableCell>
                                        {/* <TableCell align="center" sx={{ color: 'grey.600', fontWeight: 500 }}>
                                            {formattedDevice.lastActive}
                                        </TableCell> */}
                                        <TableCell align="center" sx={{ color: 'grey.600', fontWeight: 500 }}>
                                            {formattedDevice.isCurrent ? (
                                                <Typography variant="caption" color="primary">
                                                    Current Session
                                                </Typography>
                                            ) : (
                                                <Typography variant="caption" color="textSecondary">
                                                    Active
                                                </Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {isLoading && (
                                <>
                                    {[...Array(rowsPerPage)].map((_, index) => (
                                        <TableRow key={`skeleton-${index}`}>
                                            {columns.map((column) => (
                                                <TableCell key={column.id} align={column.align}>
                                                    <Skeleton variant="text" />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {(!devices.length && !isLoading) && (
                    <Box sx={{
                        width: '100%',
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '200px'
                    }}>
                        <Typography>
                            No active devices found
                        </Typography>
                    </Box>
                )}
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );

    return (
        <CustomModal
            open={openLoggedInDevices}
            title={"Logged In Devices"}
            description={description}
            close={() => setOpenLoggedInDevices(false)}
            action={forceActions()}
            isCloseIcon={false}
        />
    );
};

export default LoggedInDevices;