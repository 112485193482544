// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Skeleton } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import LogoutIcon from '@mui/icons-material/Logout';
import { useEffect, useState } from 'react';
// import moment from 'moment';
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import { axiosGetCall, axiosPostCall } from '../../services/apisCall';

const MainSection = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'scroll',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
        height: 'auto',
    },
}));

const Card = styled('div')(({ theme }) => ({
    height: 'auto',
    maxHeight: 'calc(100%)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: "6px",
    border: '1px solid',
    overflow: 'hidden',
    backgroundColor: theme.palette.card.main,
    borderColor: theme.palette.grey[300],
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const HeadingSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: "10px",
        flexWrap: 'wrap',
    },
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[700],
    fontSize: '20px',
    fontWeight: 600
}));

const DeviceTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: window.innerHeight - 225,
    '& .MuiTableCell-root': {
        padding: '12px 16px',
    },
}));

const LoggedInDevicesTabPanel = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [devicesList, setDevicesList] = useState([]);
    const [isListLoading, setIsListLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [currentSessionId, setCurrentSessionId] = useState(null);

    const fetchDevicesList = async () => {
        try {
            setIsListLoading(true);
            const skip = rowsPerPage * page;
            const payload = { skip, limit: rowsPerPage };
            
            // Modified API call to handle response properly
            const response = await axiosGetCall("loggedInDevices", payload);
            // Check if response exists and has data property
            if (response && Array.isArray(response.data)) {
                setDevicesList(response.data);
                setCount(response.data.length);
                
                // Find current session - modify this logic based on your actual API response
                const currentSession = response.data.find(device => device.isCurrent) || 
                                      response.data[0]; // Fallback to first device if none marked as current
                if (currentSession) {
                    setCurrentSessionId(currentSession.session);
                }
            } else {
                throw new Error("Invalid response format from server");
            }
        } catch (error) {
            console.error("Error fetching devices:", error);
            renderErrorMessage(error?.message || "Failed to fetch devices");
            setDevicesList([]);
            setCount(0);
        } finally {
            setIsListLoading(false);
        }
    };

    const terminateSession = async (sessionToTerminate) => {
        if (!currentSessionId) {
            renderErrorMessage("Current session ID is missing.");
            return;
        }
    
        try {
            const response = await axiosPostCall(
                "terminateLoggedIn", 
                { sessiontoken: sessionToTerminate }, 
                false, 
                { "session-token": currentSessionId } // Pass current session token as header
            );
    
            if (response) {
                renderSuccessMessage("Session terminated successfully.");
                fetchDevicesList(); // Refresh the device list
            } else {
                throw new Error("Failed to terminate session.");
            }
        } catch (error) {
            console.error("Error terminating session:", error);
            renderErrorMessage(error?.message || "Failed to terminate session.");
        }
    };
    
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchDevicesList();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        fetchDevicesList();
    };

    useEffect(() => {
        fetchDevicesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { id: 'device', label: 'Device', minWidth: 150 },
        { id: 'location', label: 'Location', minWidth: 150 },
        // { id: 'lastActive', label: 'Last Active', minWidth: 120, align: 'center' },
        { id: 'actions', label: 'Actions', minWidth: 80, align: 'center' },
    ];

    const formatDeviceData = (device) => {
        const deviceInfo = device.devices || {};
        const locationInfo = device.location || {};
        
        const deviceName = deviceInfo.device || 'Unknown Device';
        const browser = deviceInfo.browser ? ` (${deviceInfo.browser})` : '';
        const os = deviceInfo.os ? `, ${deviceInfo.os}` : '';
        
        let location = 'Unknown';
        if (locationInfo.city && locationInfo.country) {
            location = `${locationInfo.city}, ${locationInfo.country}`;
        } else if (locationInfo.bogon) {
            location = 'Localhost';
        } else if (locationInfo.ip) {
            location = locationInfo.ip;
        }
        
        // const lastActive = device.lastActive ? 
        //     moment(device.lastActive).format('D MMM, YYYY hh:mm A') : 
        //     moment().format('D MMM, YYYY hh:mm A');
        
        return {
            device: `${deviceName}${browser}${os}`,
            location,
            // lastActive,
            session: device.session,
            isCurrent: device.session === currentSessionId
        };
    };
    

    return (
        <MainSection>
            <ToastContainer />
            <Card>
                <HeadingSection>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <HeadingTypography>
                            Logged In Devices
                        </HeadingTypography>
                    </Box>
                </HeadingSection>

                <DeviceTableContainer>
                    <Table stickyHeader aria-label="devices table" size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        sx={{ backgroundColor: 'grey.300', color: 'grey.700' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isListLoading && devicesList.length > 0 ? (
                                devicesList.map((device) => {
                                    const formattedDevice = formatDeviceData(device);
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={device.session || Math.random()}>
                                            <TableCell>{formattedDevice.device}</TableCell>
                                            <TableCell>{formattedDevice.location}</TableCell>
                                            {/* <TableCell align="center">{formattedDevice.lastActive}</TableCell> */}
                                            <TableCell align="center">
                                                {!formattedDevice.isCurrent ? (
                                                    <IconButton 
                                                    onClick={() => terminateSession(device.session)} 
                                                    color="error" 
                                                    size="small"
                                                    disabled={!device.session}
                                                >
                                                    <LogoutIcon fontSize="small" />
                                                </IconButton>
                                                ) : (
                                                    <Typography variant="caption" color="textSecondary">
                                                        Current Session
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : isListLoading ? (
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} align={column.align}>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center" sx={{ py: 4 }}>
                                        <Typography>No active devices found</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </DeviceTableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        color: 'grey.600',
                        borderTop: '0.5px solid',
                        borderColor: 'grey.300'
                    }}
                />
            </Card>
        </MainSection>
    );
};

export default LoggedInDevicesTabPanel;